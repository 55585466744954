import React from 'react';
import PropTypes from 'prop-types';

import {makeUrl} from '../../../../../model/backend-api';
import Slider, {imageSliderBreakpoints} from '../../../../components/Slider';
import Slide from '../../../../components/Slider/Slide';
import {StyledImage} from './styles';

const ImageSlider = ({images, isHostUrl}) => {
    return (
        <Slider imageSlider breakpoints={imageSliderBreakpoints}>
            {images.map((image, index) => (
                <Slide key={index}>
                    <StyledImage
                        height="200px"
                        src={isHostUrl ? makeUrl(image) : image}
                        alt={`Vorschaubild-${index}`}
                        responsive
                    />
                </Slide>
            ))}
        </Slider>
    );
};

ImageSlider.propTypes = {
    images: PropTypes.arrayOf(PropTypes.string),
    isHostUrl: PropTypes.bool,
};

ImageSlider.defaultProps = {
    images: [],
    isHostUrl: true,
};

export default ImageSlider;
